import app from './modules/app'
import sidebar from './modules/sidebar'
import rsvp from './modules/rsvp'

const modules = {
  app,
  sidebar,
  rsvp,
}

export default modules
