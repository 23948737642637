





























































import { Component, Vue } from 'vue-property-decorator'
import { navigation } from '@/data/navigation'
import { NavigationLink } from '@/models/NavigationLink'
import modules from '@/store/modules'

@Component
export default class TheSideBar extends Vue {
  sidebar = modules.sidebar

  get navigationItems(): NavigationLink[] {
    return navigation
  }

  handleSidebarEvent(value: boolean): void {
    if (value) {
      modules.sidebar.open()
    } else {
      modules.sidebar.close()
    }
  }

  handleNavigationClick(item: NavigationLink): void {
    if (item.name) {
      this.$router.push({ name: item.name })
    } else if (item.action) {
      item.action()
    } else if (item.href) {
      document.location.href = item.href
    }
  }
}
