import { GuestDTO } from './GuestDTO'

export interface GuestRSVP {
  guestId: number
  firstName: string
  lastName: string
  isInvitedToRehearsalDinner: boolean
  isAttendingWedding: boolean
  isAttendingWelcomeReception: boolean
  isAttendingBrunch: boolean
  isAttendingRehearsalDinner: boolean
  guestGroupId: number
  isRidingBus: boolean
  email: string
  phone: string
  dietaryRestrictions: string
  songRequests: string
  notes: string
}

export class GuestRSVPDTO implements GuestRSVP {
  guestId: number
  firstName: string
  lastName: string
  isInvitedToRehearsalDinner: boolean
  isAttendingWelcomeReception: boolean
  isAttendingRehearsalDinner: boolean
  isAttendingWedding: boolean
  isAttendingBrunch: boolean
  guestGroupId: number
  isRidingBus: boolean
  email: string
  phone: string
  dietaryRestrictions: string
  songRequests: string
  notes: string

  constructor(guestDTO: GuestDTO) {
    if (!guestDTO) {
      return
    }
    this.guestId = guestDTO.guestId
    this.firstName = guestDTO.firstName
    this.lastName = guestDTO.lastName
    this.isInvitedToRehearsalDinner = guestDTO.rehearsalDinnerInvite

    this.isAttendingRehearsalDinner = null
    this.isAttendingWedding = null
    this.isAttendingBrunch = null
    this.guestGroupId = guestDTO.guestGroupId
    this.isRidingBus = null
    this.email = null
    this.phone = null
    this.dietaryRestrictions = null
    this.songRequests = null
    this.notes = null
  }
}
