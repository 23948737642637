import { VuexModule, Module, Action } from 'vuex-class-modules'

// register module (could be in any file)
import store from '@/store/index'
import { GuestDTO } from '../../models/dto/GuestDTO'
import guest from '../../services/guest'
import { GuestRSVPDTO } from '../../models/dto/GuestRSVPDTO'

@Module({ generateMutationSetters: true })
class RSVPModule extends VuexModule {
  // state
  _guestGroupId: number = null
  _guestGroup: GuestDTO[] = []
  _guestRSVPs: GuestRSVPDTO[] = []
  _allGuestHaveAttendanceSelection: boolean = false
  _areAllGuestsNotAttendingWedding: boolean = false
  _allGuestEventAttendanceAnswered: boolean = false
  _allGuestsHaveFirstAndLastName: boolean = false
  _showAttendeesForm: boolean = false
  _showEventsForm: boolean = false
  _showContactForm: boolean = false
  _showConfirmation: boolean = false
  _showOtherForm: boolean = false
  _showThanks: boolean = false
  _submitting: boolean = false
  _error: boolean = false
  _isPasscodeValid: boolean = false

  // getters
  get guestGroupId(): number {
    return this._guestGroupId
  }

  get guestGroup(): GuestDTO[] {
    return this._guestGroup
  }

  get guestRSVPs(): GuestRSVPDTO[] {
    return this._guestRSVPs
  }

  get allGuestHaveAttendanceSelection(): boolean {
    return this._allGuestHaveAttendanceSelection
  }

  get areAllGuestsNotAttendingWedding(): boolean {
    return this._areAllGuestsNotAttendingWedding
  }

  get allGuestEventAttendanceAnswered(): boolean {
    return this._allGuestEventAttendanceAnswered
  }

  get showContactForm(): boolean {
    return this._showContactForm
  }

  get showConfirmation(): boolean {
    return this._showConfirmation
  }

  get allGuestsHaveFirstAndLastName(): boolean {
    return this._allGuestsHaveFirstAndLastName
  }

  get showAttendeesForm(): boolean {
    return this._showAttendeesForm
  }

  get showEventsForm(): boolean {
    return this._showEventsForm
  }

  get showOtherForm(): boolean {
    return this._showOtherForm
  }

  get showThanks(): boolean {
    return this._showThanks
  }

  get submitting(): boolean {
    return this._submitting
  }

  get error(): boolean {
    return this._error
  }

  get isPasscodeValid(): boolean {
    return this._isPasscodeValid
  }

  // mutations (mutations are autogenerated for each root level state field)

  // actions

  @Action
  initialize(): void {
    this._guestGroupId = null
    this._guestGroup = []
    this._guestRSVPs = []
    this._allGuestHaveAttendanceSelection = false
    this._areAllGuestsNotAttendingWedding = false
    this._allGuestEventAttendanceAnswered = false
    this._allGuestsHaveFirstAndLastName = false
    this._showContactForm = false
    this._showConfirmation = false
    this._showAttendeesForm = false
    this._showEventsForm = false
    this._showOtherForm = false
    this._showThanks = false
    this._submitting = false
    this._error = false
    this._isPasscodeValid = false
  }

  @Action
  setGuestGroupId(guestGroupId: number) {
    if (!guestGroupId) {
      this.setGuestGroup([])
    }
    this.setShowAttendeesForm(!!guestGroupId)
    this._guestGroupId = guestGroupId
  }

  @Action
  setGuestGroup(guestGroup: GuestDTO[]) {
    this._guestGroup = guestGroup
  }

  @Action
  checkPasscode(passcode: string): void {
    this._isPasscodeValid = passcode === 'letsparty930'
  }

  @Action
  async fetchGuestGroup(): Promise<void> {
    const guestGroupResponse = await guest.groupById(this._guestGroupId)
    const guestGroup = guestGroupResponse.data.data
    this._guestGroup = guestGroup
    this._guestRSVPs = this._guestGroup.map((guest) => new GuestRSVPDTO(guest))
  }

  @Action
  setGuestIsAttendingWedding(payload: {
    guestId: number
    value: boolean
  }): void {
    const guestId = payload.guestId

    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].isAttendingWedding = payload.value
    this.checkIfAllGuestsHaveAttendanceSelection()
    this.checkIfAllGuestsHaveFirstAndLastName()
    this.checkIfAllGuestsAreNotAttendingWedding()
    this.calculateAllGuestEventAttendanceAnswered()

    this.setGuestIsAttendingWelcomeReception({ guestId, value: null })
    this.setGuestIsAttendingBrunch({ guestId, value: null })
    this.setGuestIsAttendingRehearsalDinner({ guestId, value: null })
  }

  @Action
  checkIfAllGuestsHaveAttendanceSelection(): void {
    this._allGuestHaveAttendanceSelection = this._guestRSVPs.every(
      (g) => g.isAttendingWedding !== null
    )
  }

  @Action
  checkIfAllGuestsHaveFirstAndLastName(): void {
    this._allGuestsHaveFirstAndLastName = this._guestRSVPs
      .filter((g) => g.isAttendingWedding)
      .every((g) => g.firstName !== null && g.lastName !== null)
  }

  @Action
  checkIfAllGuestsAreNotAttendingWedding(): void {
    this._areAllGuestsNotAttendingWedding = this._guestRSVPs.every(
      (g) => g.isAttendingWedding === false
    )
  }

  @Action
  setGuestFirstName(payload: { guestId: number; value: string }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].firstName = payload.value
    this.checkIfAllGuestsHaveFirstAndLastName()
  }

  @Action
  setGuestLastName(payload: { guestId: number; value: string }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].lastName = payload.value
    this.checkIfAllGuestsHaveFirstAndLastName()
  }

  @Action
  setGuestIsAttendingWelcomeReception(payload: {
    guestId: number
    value: boolean
  }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].isAttendingWelcomeReception = payload.value
    this.calculateAllGuestEventAttendanceAnswered()
  }

  @Action
  setGuestIsAttendingBrunch(payload: {
    guestId: number
    value: boolean
  }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].isAttendingBrunch = payload.value
    this.calculateAllGuestEventAttendanceAnswered()
  }

  @Action
  setGuestIsAttendingRehearsalDinner(payload: {
    guestId: number
    value: boolean
  }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].isAttendingRehearsalDinner = payload.value
    this.calculateAllGuestEventAttendanceAnswered()
  }

  @Action
  setGuestIsRidingBus(payload: { guestId: number; value: boolean }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].isRidingBus = payload.value
  }

  @Action
  setGuestDietaryRestrictions(payload: {
    guestId: number
    value: string
  }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].dietaryRestrictions = payload.value
  }

  @Action
  setGuestSongRequests(payload: { guestId: number; value: string }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].songRequests = payload.value
  }

  @Action
  setGuestNotes(payload: { guestId: number; value: string }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].notes = payload.value
  }

  @Action
  calculateAllGuestEventAttendanceAnswered(): void {
    const attendingGuests = this._guestRSVPs.filter(
      (guest) => guest.isAttendingWedding
    )
    this._allGuestEventAttendanceAnswered =
      attendingGuests.length &&
      attendingGuests.every(
        (guest) =>
          guest.isAttendingWelcomeReception !== null &&
          guest.isAttendingBrunch !== null &&
          (!guest.isInvitedToRehearsalDinner ||
            guest.isAttendingRehearsalDinner !== null)
      )
  }

  @Action
  setShowContactForm(value: boolean): void {
    this._showContactForm = value
  }

  @Action
  setShowConfirmation(value: boolean): void {
    this._showConfirmation = value
  }

  @Action
  setGuestPhone(payload: { guestId: number; value: string }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].phone = payload.value
  }

  @Action
  setGuestEmail(payload: { guestId: number; value: string }): void {
    const guestIndex = this._guestRSVPs.findIndex(
      (g) => g.guestId === payload.guestId
    )
    this._guestRSVPs[guestIndex].email = payload.value
  }

  @Action
  setShowAttendeesForm(value: boolean): void {
    this._showAttendeesForm = value
  }

  @Action
  setShowEventsForm(value: boolean): void {
    this._showEventsForm = value
  }

  @Action
  setShowOtherForm(value: boolean): void {
    this._showOtherForm = value
  }

  @Action
  setShowThanks(value: boolean): void {
    this._showThanks = value
  }

  @Action
  goToAttendeesForm(): void {
    this.setShowAttendeesForm(true)
    this.setShowEventsForm(false)
    this.setShowOtherForm(false)
    this.setShowContactForm(false)
    this.setShowConfirmation(false)
    this.setShowThanks(false)
  }

  @Action
  goToEventsForm(): void {
    this.setShowAttendeesForm(false)
    this.setShowEventsForm(true)
    this.setShowOtherForm(false)
    this.setShowContactForm(false)
    this.setShowConfirmation(false)
    this.setShowThanks(false)
  }

  @Action
  goToOtherForm(): void {
    this.setShowAttendeesForm(false)
    this.setShowEventsForm(false)
    this.setShowOtherForm(true)
    this.setShowContactForm(false)
    this.setShowConfirmation(false)
    this.setShowThanks(false)
  }

  @Action
  goToContactForm(): void {
    this.setShowAttendeesForm(false)
    this.setShowEventsForm(false)
    this.setShowOtherForm(false)
    this.setShowContactForm(true)
    this.setShowConfirmation(false)
    this.setShowThanks(false)
  }

  @Action
  goToConfirmation(): void {
    this.setShowAttendeesForm(false)
    this.setShowEventsForm(false)
    this.setShowOtherForm(false)
    this.setShowContactForm(false)
    this.setShowConfirmation(true)
    this.setShowThanks(false)
  }

  @Action
  goToThanks(): void {
    this.setShowAttendeesForm(false)
    this.setShowEventsForm(false)
    this.setShowOtherForm(false)
    this.setShowContactForm(false)
    this.setShowConfirmation(false)
    this.setShowThanks(true)
  }

  @Action
  async submitRSVP(): Promise<void> {
    this._submitting = true
    this._error = false
    try {
      await guest.rsvp(this._guestRSVPs)
      this.goToThanks()
    } catch (e) {
      this._error = true
      console.log(e)
    }
    this._submitting = false
  }
}
export default new RSVPModule({ store, name: 'rsvp' })
