













































import { Component, Vue } from 'vue-property-decorator'
import { navigation } from '@/data/navigation'
import { NavigationLink } from '@/models/NavigationLink'
import modules from '@/store/modules'
import TheAppBarNavigationItem from '@/components/TheAppBarNavigationItem.vue'

@Component({ components: { TheAppBarNavigationItem } })
export default class TheAppBar extends Vue {
  get topNavigationItems(): NavigationLink[] {
    return navigation.filter(
      (item) => item.location === 'left' || item.location === 'right'
    )
  }

  get leftNavigationItems(): NavigationLink[] {
    return this.topNavigationItems.filter((item) => item.location === 'left')
  }

  get rightNavigationItems(): NavigationLink[] {
    return this.topNavigationItems.filter((item) => item.location === 'right')
  }

  openSidebar(): void {
    modules.sidebar.open()
  }
}
