























































import { NavigationLink } from '@/models/NavigationLink'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

@Component({})
export default class TheAppBarNavigationItem extends Vue {
  @Prop({ type: Object, required: true }) readonly item!: NavigationLink

  handleNavigationClick(item: NavigationLink): void {
    if (item.name) {
      this.$router.push({ name: item.name })
    } else if (item.action) {
      item.action()
    }
  }

  isRouteActive(item: NavigationLink): boolean {
    const rawLocation: RawLocation = { name: item.name }
    const route = this.$router.resolve(rawLocation)
    let path = route.href
    if (path[path.length - 1] === '/') {
      path = path.slice(0, -1)
    }
    const matchedRoute = this.$route.matched.find(
      (route) => route.path === path
    )
    return !!matchedRoute
  }
}
