import { VuexModule, Module, Action } from 'vuex-class-modules'

// register module (could be in any file)
import store from '@/store/index'

@Module({ generateMutationSetters: true })
class SidebarModule extends VuexModule {
  // state
  _isOpen = false

  // getters
  get isOpen(): boolean {
    return this._isOpen
  }

  // mutations (mutations are autogenerated for each root level state field)

  // actions
  @Action
  open() {
    this._isOpen = true
  }

  @Action
  close() {
    this._isOpen = false
  }
}
export default new SidebarModule({ store, name: 'sidebar' })
