import axios from 'axios'

/**
 * Returns the given prefix with a trailing dot, if the prefix is not empty.
 *
 * @param prefix - The prefix to add a dot to.
 * @returns The prefix with a trailing dot, or an empty string if the prefix is empty.
 */
export const hostPrefix = (prefix: string): string =>
  prefix ? `${prefix}.` : ''

/**
 * Returns the base URL for the API.
 *
 * @param prefix - An optional prefix to use for the base URL.
 * @returns The base URL.
 */
export const baseUrl = (prefix = 'api'): string => {
  let host
  if (process.env.VUE_APP_BASE_URL_OVERRIDE) {
    host = process.env.VUE_APP_BASE_URL_OVERRIDE
  } else {
    const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
    if (hostSubdomain === 'localhost') {
      host = 'localhost:3000'
    } else {
      host = `${prefix}.themadays.com`
    }
  }
  axios.defaults.baseURL = `http://${host}`
  return `${host}`
}

/**
 * Returns the base URL for the current host.
 *
 * @returns The base URL for the current host.
 */
export const hostBaseUrl = (): string => {
  let host
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'localhost') {
    host = 'localhost:3000'
  } else {
    host = 'themadays.com'
  }
  return `http://${host}`
}
