import { NavigationLink } from '@/models/NavigationLink'

export const navigation: NavigationLink[] = [
  {
    label: 'Wedding Gallery',
    href: 'wedding-gallery',
    name: 'wedding-gallery',
    location: 'left',
    params: {},
    permission: [],
  },
  {
    label: 'Engagement Gallery',
    href: 'gallery',
    name: 'gallery',
    location: 'left',
    params: {},
    permission: [],
  },
  {
    label: 'Details',
    href: 'details',
    name: 'details',
    location: 'left',
    params: {},
    permission: [],
  },
  {
    label: 'Our Story',
    href: 'our-story',
    name: 'our-story',
    location: 'left',
    params: {},
    permission: [],
  },
  {
    label: 'Wedding Party',
    href: 'wedding-party',
    name: 'wedding-party',
    location: 'right',
    params: {},
    permission: [],
  },
  {
    label: 'Things To Do',
    href: 'things-to-do',
    name: 'things-to-do',
    location: 'right',
    params: {},
    permission: [],
  },
  {
    label: 'Registry',
    href: 'registry',
    name: 'registry',
    location: 'right',
    params: {},
    permission: [],
  },
  // {
  //   label: 'RSVP',
  //   href: 'rsvp',
  //   name: 'rsvp',
  //   location: 'right',
  //   params: {},
  //   permission: [],
  // },
]
