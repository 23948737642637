import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import { GuestDTO } from '../models/dto/GuestDTO'
import { ResultDTO } from '../models/dto/ResultDTO'
import { GuestRSVPDTO } from '../models/dto/GuestRSVPDTO'

const httpService: HttpService = new HttpService()

export default {
  searchByLastName(
    lastName: string
  ): Promise<AxiosResponse<ResultDTO<GuestDTO[]>>> {
    const url = `http://${baseUrl()}/guest/lastName/${lastName}`
    return httpService.get(url)
  },
  groupById(groupId: number): Promise<AxiosResponse<ResultDTO<GuestDTO[]>>> {
    const url = `http://${baseUrl()}/guest/group/${groupId}`
    return httpService.get(url)
  },
  rsvp(guestRSVPDTOs: GuestRSVPDTO[]): Promise<AxiosResponse<ResultDTO<null>>> {
    const url = `http://${baseUrl()}/guest/rsvp`
    return httpService.post(url, guestRSVPDTOs)
  },
}
